import React from 'react'
import styled from 'styled-components'

import ReactSlider from 'react-slider'

import { Flex } from 'components/shared/Grid'

import type { Range } from 'types/filters'
import { Text } from 'components/shared/Typography'
import useLocation from 'hooks/useLocation'

type SliderProps = {
  label: string
  extremes: Range
  value: Range
  onChange: (val: Range) => void
  step?: number
  minDistance?: number
  lightLabel?: boolean
}

const SliderWrapper = styled.div`
  width: 100%;
  ${({ theme }) => theme.media.sm.max} {
    & > ${Flex} > ${Text} {
      font-size: 12px;
    }
  }
`

const StyledSlider = styled(ReactSlider)`
  width: 100%;
  padding: 0.5rem 0 1rem;

  .slider-track {
    background: #cccccc;
    height: 2px;
    border-radius: 8px;
  }

  .slider-thumb {
    width: 16px;
    height: 16px;

    background-color: ${({ theme }) => theme.colors.primary};
    border: 2px solid ${({ theme }) => theme.colors.primary};
    border-radius: 50%;

    transform: translate3d(0, -7px, 0);

    cursor: pointer;
    outline: none;
  }

  .slider-track.slider-track-1 {
    background: ${({ theme }) => theme.colors.primary};
    border-radius: 8px;
  }
`

const StyledText = styled(Text)`
  b {
    font-size: 18px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.black};
  }
  ${({ theme }) => theme.media.sm.max} {
    font-size: 12px;
    b {
      font-size: 1rem;
    }
  }
`

const RangeInput: React.FC<SliderProps> = ({
  label,
  extremes,
  value,
  step = 1,
  minDistance = 1,
  lightLabel = true,
  onChange,
}) => {
  return (
    <SliderWrapper>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        margin="0 0 0.25rem"
      >
        <Text
          size={lightLabel ? 13 : 14}
          transform={lightLabel ? `uppercase` : `initial`}
          themecolor={lightLabel ? `light` : `dark`}
          weight={lightLabel ? 400 : 300}
        >
          {label}
        </Text>
        <StyledText size={13} weight={400} themecolor="light">
          <b>{value.min}</b> do <b>{value.max}</b> m<sup>2</sup>
        </StyledText>
      </Flex>
      <StyledSlider
        min={extremes.min}
        max={extremes.max}
        className="horizontal-slider"
        thumbClassName="slider-thumb"
        trackClassName="slider-track"
        value={[value.min, value.max]}
        defaultValue={[value.min, value.max]}
        step={step}
        minDistance={minDistance}
        onChange={(values) => {
          if (Array.isArray(values)) {
            onChange({ min: values[0], max: values[1] })
          }
        }}
      />
    </SliderWrapper>
  )
}

export default RangeInput
