import React, { useState, useRef } from 'react'
import styled, { css } from 'styled-components'

import { Text } from 'components/shared/Typography'
import { Flex } from 'components/shared/Grid'
import Button from 'components/shared/Button'
import Icon from 'components/shared/Icon'

import useOutsideClick from 'hooks/useOutsideClick'

import dropdownIcon from 'assets/icons/arrow-down.svg'
import checkIcon from 'assets/icons/check-white.svg'

export type Option = {
  value: string
  label: string
  callback?: () => void
}

type SelectProps = {
  label?: string
  placeholder?: string
  defaultOptions: Option[]
  activeOptions: Option[]
  setNewOptions?: (val: Option['value'][]) => void
}

const Options = styled.div`
  position: absolute;
  top: calc(100% + 1px);
  left: -1px;
  right: -1px;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-top: none;
  max-height: 250px;
  overflow-y: auto;
  transition: 0.15s;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0px 6px 12px #00000015;
`

const Wrapper = styled.div<{ active: boolean }>`
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  border: 1px solid #ececec;
  transition: 0.15s;
  ${({ active }) =>
    active &&
    css`
      border-color: ${({ theme }) => theme.colors.primary} !important;
      & > ${Options} {
        opacity: 1;
        visibility: visible;
      }
    `}
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0.75rem 1rem;
  transition: 0.25s;
  cursor: pointer;
  & > ${Button} {
    pointer-events: none;
    padding: 0.6rem;
  }
  & > ${Flex} {
    width: calc(100% - 35px);
    ${({ theme }) => theme.media.sm.max} {
      & > ${Text} {
        font-size: 12px;
      }
    }
  }
  &:hover {
    background: ${({ theme }) => theme.colors.lightgray};
  }
  ${({ theme }) => theme.media.sm.min} {
    padding: 1rem 1rem 1rem 1.25rem;
  }
`

const Check = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 18px;
  min-height: 18px;
  max-height: 18px;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  margin-right: 0.75rem;
  transition: 0.25s;
`

const OptionItem = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  min-height: 36px;
  padding: 0.45rem 0.75rem;
  cursor: pointer;
  transition: 0.25s;
  &:hover {
    background: ${({ theme }) => theme.colors.lightgray};
  }
  & > ${Check} {
    span {
      display: none;
    }
  }
  ${({ theme }) => theme.media.sm.max} {
    & > ${Text} {
      font-size: 14px;
    }
  }
  ${({ active }) =>
    active &&
    css`
      & > ${Check} {
        background: ${({ theme }) => theme.colors.primary};
        span {
          display: block;
        }
      }
    `}
`

const WrappedText = styled(Text)`
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`

const ValueText = styled(WrappedText)`
  ${({ theme }) => theme.media.sm.max} {
    font-size: 15px !important;
  }
`

const Select: React.FC<SelectProps> = ({
  label,
  placeholder = '',
  setNewOptions,
  defaultOptions,
  activeOptions,
}) => {
  const [visible, setVisible] = useState(false)

  const toggleValue = (value: Option['value']) => {
    if (setNewOptions) {
      const activeValueOptions = activeOptions.map((el) => el.value)
      if (activeValueOptions.includes(value)) {
        setNewOptions(activeValueOptions.filter((el) => el !== value))
        return
      }
      setNewOptions([...activeValueOptions, value])
    }
  }

  const ref = useRef(null)

  useOutsideClick({
    ref,
    handler: () => setVisible(false),
    condition: visible,
  })

  const optionsString = activeOptions
    .map((el) => el.label)
    .sort()
    .join(', ')

  const value = activeOptions.length > 0 ? optionsString : placeholder

  return (
    <Wrapper ref={ref} active={visible}>
      <Header onClick={() => setVisible(!visible)}>
        <Flex direction="column">
          {label && (
            <Text size={13} transform="uppercase" themecolor="light">
              {label}
            </Text>
          )}
          <ValueText
            title={value}
            size={18}
            weight={activeOptions.length > 0 ? 500 : 400}
            themecolor={activeOptions.length > 0 ? 'black' : 'darkgray'}
          >
            {value}
          </ValueText>
        </Flex>
        <Button as="div" round roundSize="30px" white100>
          <Icon src={dropdownIcon} />
        </Button>
      </Header>
      <Options>
        {defaultOptions.map((option) => (
          <OptionItem
            key={option.value}
            active={activeOptions.some((el) => el.value === option.value)}
            onClick={() =>
              option.callback ? option.callback() : toggleValue(option.value)
            }
          >
            <Check>
              <Icon src={checkIcon} />
            </Check>
            <WrappedText>{option.label}</WrappedText>
          </OptionItem>
        ))}
      </Options>
    </Wrapper>
  )
}

export default Select
